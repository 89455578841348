import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Edit } from "react-feather";
import {
  shopkeeperById,
  shopkeeperStatus,
  clearCurrentShopkeeper,
} from "../../../../../store/ducks/Shopkeeper";
import { useSelector, useDispatch } from "react-redux";
import TableInfo from "../../../../../components/TableInfo";
import SkeletonInfo from "../../../../../components/SkeletonInfo";

const ShopkeeperInfo = ({ editShopkeeper }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  const [showSkeleton, setShowSkeleton] = useState(true);
  const [newPerson, setNewPerson] = useState([]);
  const [newUsuario, setNewUsario] = useState([]);
  const [newConta, setNewConta] = useState([]);
  const [newEderecoCpf, setNewEderecoCpf] = useState([]);
  const [newEmpresa, setNewEmpresa] = useState([]);
  const [newTarifa, setNewTarifa] = useState([]);
  const [newEderecoCnpj, setNewEderecoCnpj] = useState([]);

  const { currentShopkeeper } = useSelector((state) => state.shopkeeper);
  const elevetionAccordion = 3;
  const spaceColumn = 2;

  useEffect(() => {
    dispatch(shopkeeperById(id));
    dispatch(shopkeeperStatus());
  }, []);

  useEffect(() => {
    currentShopkeeper && editShopkeeper(currentShopkeeper);
  }, [currentShopkeeper]);

  useEffect(() => {
    if (currentShopkeeper.length !== 0) {
      const {
        usuario,
        tarifa,
        conta,
        endereco_cpf,
        pessoa,
        empresa,
        endereco_cnpj,
      } = currentShopkeeper;

      setNewPerson(
        Object.keys(pessoa)
          .map((key) => {
            return {
              label: key,
              value: pessoa[key],
            };
          })
          .filter(({ label }) => {
            return (
              label !== "id" &&
              label !== "pai" &&
              label !== "mae" &&
              label !== "sexo" &&
              label !== "emissao" &&
              label !== "emissor" &&
              label !== "rg"
            );
          })
      );
      setNewUsario(
        Object.keys(usuario)
          .map((key) => {
            return {
              label: key,
              value: usuario[key],
            };
          })
          .filter((use) => {
            return (
              use.label !== "id" &&
              use.label !== "senha" &&
              use.label !== "numero_estabelecimento" &&
              use.label !== "terminal" &&
              use.label !== "chave"
            );
          })
      );
      setNewConta(
        Object.keys(conta)
          .map((key) => {
            return {
              label: key,
              value: conta[key],
            };
          })
          .filter((cont) => {
            return cont.label !== "id";
          })
      );

      setNewEderecoCpf(
        Object.keys(endereco_cpf)
          .map((key) => {
            return {
              label: key,
              value: endereco_cpf[key],
            };
          })
          .filter((end) => {
            return end.label !== "id";
          })
      );

      setNewTarifa(
        Object.keys(tarifa)
          .map((key) => {
            return {
              label: key,
              value: tarifa[key],
            };
          })
          .filter((end) => {
            return end.label !== "id" && end.label !== "observacao";
          })
      );
      if (empresa && endereco_cnpj) {
        setNewEmpresa(
          Object.keys(empresa)
            .map((key) => {
              return {
                label: key,
                value: empresa[key],
              };
            })
            .filter((end) => {
              return end.label !== "id";
            })
        );

        setNewEderecoCnpj(
          Object.keys(endereco_cnpj)
            .map((key) => {
              return {
                label: key,
                value: endereco_cnpj[key],
              };
            })
            .filter((end) => {
              return end.label !== "id";
            })
        );
      }

      setShowSkeleton(false);
    }
  }, [currentShopkeeper]);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentShopkeeper());
    };
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Accordion defaultExpanded elevation={elevetionAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel-content-data"
            id="panel-header-data"
          >
            <Typography variant="h2" className={classes.heading}>
              Dados
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              <Grid item>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="column" spacing={spaceColumn}>
                      <Grid item>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                            {showSkeleton ? (
                              <SkeletonInfo />
                            ) : (
                              <TableInfo title="Pessoa" data={newPerson} />
                            )}
                          </Grid>

                          <Grid
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={3}
                            className={classes.centralization}
                          >
                            {showSkeleton ? (
                              <SkeletonInfo />
                            ) : (
                              <TableInfo title="Usuário" data={newUsuario} />
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                            {showSkeleton ? (
                              <SkeletonInfo />
                            ) : (
                              <TableInfo title="Tarifa" data={newTarifa} />
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                            {showSkeleton ? (
                              <SkeletonInfo />
                            ) : (
                              <TableInfo title="Conta" data={newConta} />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item>
        <Accordion defaultExpanded elevation={elevetionAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel-content-adress-shopkeeper"
            id="panel-header-adress-shopkeeper"
          >
            <Typography variant="h2" className={classes.heading}>
              Endereço
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              <Grid item>
                <Grid container>
                  <Grid item>
                    {showSkeleton ? (
                      <SkeletonInfo />
                    ) : (
                      <TableInfo
                        title="Endereço Pessoa Física"
                        data={newEderecoCpf}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {newEmpresa.length !== 0 && (
        <Grid item>
          <Accordion defaultExpanded elevation={elevetionAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-content-adress-shopkeeper"
              id="panel-header-adress-shopkeeper"
            >
              <Typography variant="h2" className={classes.heading}>
                Empresa
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      {showSkeleton ? (
                        <SkeletonInfo />
                      ) : (
                        <TableInfo title="Empresa" data={newEmpresa} />
                      )}
                    </Grid>
                    <Grid item>
                      {showSkeleton ? (
                        <SkeletonInfo />
                      ) : (
                        <TableInfo
                          title="Endereço Pessoa Física"
                          data={newEderecoCpf}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>

    // <Grid container direction="column" spacing={3}>
    //   <Grid item>
    //     <Grid container justifyContent="flex-end">
    //       <Grid item>
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           onClick={() => editShopkeeper(currentShopkeeper)}
    //           startIcon={<Edit />}
    //         >
    //           Editar
    //         </Button>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   <Grid item>
    //     <Grid container direction="row" spacing={1}>
    //       <Grid item>
    //         {showSkeleton ? (
    //           <SkeletonInfo />
    //         ) : (
    //           <TableInfo title="Pessoa" data={newPerson} />
    //         )}
    //       </Grid>
    //       <Grid item>
    //         {showSkeleton ? (
    //           <SkeletonInfo />
    //         ) : (
    //           <TableInfo title="Usuário" data={newUsuario} />
    //         )}
    //       </Grid>
    //       <Grid item>
    //         {showSkeleton ? (
    //           <SkeletonInfo />
    //         ) : (
    //           <TableInfo title="Conta" data={newConta} />
    //         )}
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   <Grid item>
    //     <Grid container direction="row" spacing={2}>
    //       {/* <Grid item>
    //         {showSkeleton ? (
    //           <SkeletonInfo />
    //         ) : (
    //           <TableInfo title="Tarifa" data={newTarifa} />
    //         )}
    //       </Grid> */}
    //       <Grid item>
    //         {showSkeleton ? (
    //           <SkeletonInfo />
    //         ) : (
    //           <TableInfo title="Endereço Pessoa Física" data={newEderecoCpf} />
    //         )}
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   <Divider />
    //   {newEmpresa.length !== 0 && (
    //     <Grid item>
    //       <Grid container direction="row" spacing={2}>
    //         <Grid item>
    //           {showSkeleton ? (
    //             <SkeletonInfo />
    //           ) : (
    //             <TableInfo title="Empresa" data={newEmpresa} />
    //           )}
    //         </Grid>
    //         <Grid item>
    //           {showSkeleton ? (
    //             <SkeletonInfo />
    //           ) : (
    //             <TableInfo title="Endereço PJ" data={newEderecoCnpj} />
    //           )}
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   )}
    // </Grid>
  );
};

export default ShopkeeperInfo;
