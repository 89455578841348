import React from "react";
import { PersonAdd } from "@material-ui/icons";
import Page from "../../../../components/Page";
import List from "./List";

export const UserList = () => {
  return (
    <Page Icon={PersonAdd} title="Adicionar usuário" buttonRouter="/userConfig">
      <List />
    </Page>
  );
};

export default UserList;
