import React from "react";
import { GroupAdd } from "@material-ui/icons";
import Page from "../../../../components/Page";
import List from "./List";

export const GroupList = () => {
  return (
    <Page Icon={GroupAdd} buttonRouter="/">
      <List />
    </Page>
  );
};

export default GroupList;
