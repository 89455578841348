import { api } from "../../../services/api/api";

const getGroups = async () => {
  const res = await api.get("/group");
  return res;
};

const postGroupRelationship = async (body) => {
  const res = await api.post("/group/user-group", body);
  return res;
};

const putGroupRelationShipById = async (body) => {
  const { id, putBody } = body;
  const res = await api.put(`group/user-group/${id}`, putBody);
  return res;
};

export { getGroups, postGroupRelationship, putGroupRelationShipById };
