import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { NavLink as RouterLink } from "react-router-dom";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import Label from "../../../../../components/Label";
import { useStyles } from "../styles";

const Form = ({ formik, changeStep, handleEdit }) => {
  const classes = useStyles();
  const [isCaixa, setIsCaixa] = useState("hidden");

  const handleBank = (event) => {
    if (event.target.value === 104) {
      setIsCaixa("visible");
    } else {
      setIsCaixa("hidden");
    }
    formik.handleChange(event);
  };

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} lg={4}>
              <TextField
                id="numero_estabelecimento"
                disabled={!handleEdit}
                variant="outlined"
                name="usuario.numero_estabelecimento"
                label="Número do estabelecimento"
                value={formik.values.usuario?.numero_estabelecimento}
                onChange={handleBank}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.usuario?.numero_estabelecimento &&
                  Boolean(formik.errors.usuario?.numero_estabelecimento)
                }
                helperText={
                  formik.touched.usuario?.numero_estabelecimento &&
                  formik.errors.usuario?.numero_estabelecimento
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                id="terminal"
                disabled={!handleEdit}
                variant="outlined"
                name="usuario.terminal"
                label="Terminal (mid)"
                value={formik.values.usuario?.terminal}
                onChange={handleBank}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.usuario?.terminal &&
                  Boolean(formik.errors.usuario?.terminal)
                }
                helperText={
                  formik.touched.usuario?.terminal &&
                  formik.errors.usuario?.terminal
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                id="chave"
                disabled={!handleEdit}
                variant="outlined"
                name="usuario.chave"
                label="Chave (key)"
                value={formik.values.usuario?.chave}
                onChange={handleBank}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.usuario?.chave &&
                  Boolean(formik.errors.usuario?.chave)
                }
                helperText={
                  formik.touched.usuario?.chave && formik.errors.usuario?.chave
                }
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          style={{ marginLeft: "-20px", marginTop: "100px" }}
          justify="flex-end"
          spacing={12}
        >
          <Grid container justify="flex-end" alignItems="center" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="sm"
                rel="noopener noreferrer"
                onClick={() => changeStep(3)}
              >
                <ArrowBack className={classes.arrowIconBack} />
                Anterior
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                rel="noopener noreferrer"
                onClick={() => changeStep(5)}
              >
                Próximo
                <ArrowForward className={classes.arrowIconNext} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Form;
