import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUsers,
  getRelatedGroups,
  getUserById,
  postUser,
  editUserById,
  getValidated,
  updatedUser,
  getUserStatus,
  deleteUserById,
} from "./service";
import { emailValidation } from "../Validation";

export const userList = createAsyncThunk(
  "user/userList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUsers();
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const relatedGroups = createAsyncThunk(
  "user/relatedGroups",
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await getRelatedGroups(userId);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { nome, email } = body;
      const { data } = await postUser(body);
      dispatch(
        emailValidation({
          email,
          name: nome,
          id: data.message[0].id,
        })
      );
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOnlyUser = createAsyncThunk(
  "user/getOnlyUser",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getUserById(id);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "user/editUserById",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await editUserById(body);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getValidatedEmail = createAsyncThunk(
  "user/getValidatedEmail",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await getValidated(body);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const finishRegister = createAsyncThunk(
  "user/finishRegister",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await updatedUser(body);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userStatus = createAsyncThunk(
  "user/getUserStatus",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserStatus();
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteUserById(id);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: [],
  relatedGroups: [],
  selectedRelatedGroups: [],
  newUser: [],
  currentUser: [
    {
      email: "",
      nome: "",
      cpf: "",
      status: "",
    },
  ],
  message: null,
  statusMessage: null,
  validatedEmail: null,
  type: "",
  status: "idle",
  userStatus: [],
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    addSelectedGroups(state, action) {
      return (state = { ...state, selectedRelatedGroups: action.payload });
    },
    clearSelectedGroups(state) {
      return (state = { ...state, selectedRelatedGroups: [] });
    },
    clearUser(state) {
      return (state = {
        ...state,
        newUser: [],
        message: "",
        relatedGroups: [],
        status: "idle",
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userList.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(userList.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          data: action.payload?.message,
        });
      })
      .addCase(userList.rejected, (state, action) => {
        return (state = { ...state, status: "failed" });
      })
      .addCase(relatedGroups.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(relatedGroups.fulfilled, (state, action) => {
        return {
          ...state,
          relatedGroups: action.payload?.message,
          status: "completed",
        };
      })
      .addCase(relatedGroups.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          message: action.payload?.message,
        });
      })
      .addCase(createUser.pending, (state) => {
        return (state = { ...state, status: "loading", type: "createUser" });
      })
      .addCase(createUser.fulfilled, (state, action) => {
        return (state = {
          ...state,
          newUser: action.payload?.message,
          status: "completed",
          type: "createUser",
        });
      })
      .addCase(createUser.rejected, (state, action) => {
        return (state = {
          ...state,
          message: action.payload?.message,
          status: "failed",
          type: "createUser",
        });
      })
      .addCase(getOnlyUser.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(getOnlyUser.fulfilled, (state, action) => {
        return (state = {
          ...state,
          currentUser: action.payload?.message,
          status: "completed",
        });
      })
      .addCase(getOnlyUser.rejected, (state) => {
        return (state = { ...state, status: "failed" });
      })
      .addCase(editUser.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(editUser.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          message: action.payload?.message,
          statusMessage: action.payload?.name,
        });
      })
      .addCase(editUser.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          message: action.payload?.message,
          statusMessage: action.payload?.name,
        });
      })
      .addCase(getValidatedEmail.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(getValidatedEmail.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          message: action.payload?.message,
          statusMessage: action.payload?.name,
        });
      })
      .addCase(getValidatedEmail.rejected, (state, action) => {
        return (state = { ...state, status: "failed" });
      })
      .addCase(finishRegister.pending, (state) => {
        return (state = {
          ...state,
          status: "loading",
          type: "finishRegister",
        });
      })
      .addCase(finishRegister.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          message: action.payload?.message,
          statusMessage: action.payload?.name,
          type: "finishRegister",
        });
      })
      .addCase(finishRegister.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          message: action.payload?.message,
          statusMessage: action.payload?.name,
          type: "finishRegister",
        });
      })
      .addCase(userStatus.pending, (state) => {
        return (state = { ...state, status: "loading", type: "userStatus" });
      })
      .addCase(userStatus.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          userStatus: action.payload?.message,
          type: "userStatus",
        });
      })
      .addCase(userStatus.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          userStatus: action.payload?.message,
          type: "userStatus",
        });
      })
      .addCase(deleteUser.pending, (state) => {
        return (state = { ...state, status: "loading", type: "deleteById" });
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          type: "deleteById",
          message: action.payload?.message,
        });
      })
      .addCase(deleteUser.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          type: "deleteById",
          message: action.payload?.message,
        });
      });
  },
});

export const { addSelectedGroups, clearUser, clearSelectedGroups } =
  user.actions;

export default user.reducer;
