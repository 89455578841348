import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  header: {
    backgroundColor: () => {
      return theme.palette.type === "light" ? "#F9F9F9" : "#333";
    },
  },
  headerText: {
    fontSize: "12px",
    fontWeight: "549",
    color: "#bcbcbc",
  },
}));
