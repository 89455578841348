import React from "react";
import { Button, Grid, TextField, MenuItem } from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { useStyles } from "../styles";
import { maskReal, maskRealMoney } from "../../../../../utils/string/masks";

const Form = ({ formik, changeStep, handleEdit }) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="segmento"
            variant="outlined"
            disabled={!handleEdit}
            name="tarifa.segmento"
            label="Segmento"
            onChange={formik.handleChange}
            value={formik.values.tarifa.segmento}
            onBlur={formik.handleBlur}
            error={
              formik.touched.tarifa?.segmento &&
              Boolean(formik.errors.tarifa?.segmento)
            }
            helperText={
              formik.touched.tarifa?.segmento && formik.errors.tarifa?.segmento
            }
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                id="faturamento"
                variant="outlined"
                disabled={!handleEdit}
                name="tarifa.faturamento"
                label="Faturamento"
                value={maskRealMoney(formik.values.tarifa.faturamento)}
                onChange={(event) => {
                  formik.setFieldValue(
                    "tarifa.faturamento",
                    maskRealMoney(event.target.value)
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.tarifa?.faturamento &&
                  Boolean(formik.errors.tarifa?.faturamento)
                }
                helperText={
                  formik.touched.tarifa?.faturamento &&
                  formik.errors.tarifa?.faturamento
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                id="periodo"
                variant="outlined"
                disabled={!handleEdit}
                name="tarifa.periodo"
                label="Periodo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tarifa.periodo}
                error={
                  formik.touched.tarifa?.periodo &&
                  Boolean(formik.errors.tarifa?.periodo)
                }
                helperText={
                  formik.touched.tarifa?.periodo &&
                  formik.errors.tarifa?.periodo
                }
                fullWidth
                select
                required
              >
                <MenuItem key="mensal" value="Mensal">
                  Mensal
                </MenuItem>
                <MenuItem key="anual" value="Anual">
                  Anual
                </MenuItem>
                <MenuItem key="semestre" value="Semestre">
                  Semestre
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                id="risco"
                variant="outlined"
                disabled={!handleEdit}
                name="tarifa.risco"
                label="Risco"
                select
                onChange={formik.handleChange}
                value={formik.values.tarifa.risco}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.tarifa?.risco &&
                  Boolean(formik.errors.tarifa?.risco)
                }
                helperText={
                  formik.touched.tarifa?.risco &&
                  formik.errors.tarifa?.risco
                }
                fullWidth
                required
              >
                <MenuItem key="alto" value="alto">
                  Alto
                </MenuItem>
                <MenuItem key="medio" value="medio">
                  Médio
                </MenuItem>
                <MenuItem key="baixo" value="baixo">
                  Baixo
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                id="cobranca"
                variant="outlined"
                disabled={!handleEdit}
                name="tarifa.tipo_cobranca"
                label="Tipo Cobrança"
                select
                onChange={formik.handleChange}
                value={formik.values.tarifa.tipo_cobranca}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.tarifa?.tipo_cobranca &&
                  Boolean(formik.errors.tarifa?.tipo_cobranca)
                }
                helperText={
                  formik.touched.tarifa?.tipo_cobranca &&
                  formik.errors.tarifa?.tipo_cobranca
                }
                fullWidth
                required
              >
                <MenuItem key="fixo" value="Fixo">
                  Fixo
                </MenuItem>
                <MenuItem key="porcentagem" value="Porcentagem">
                  Porcentagem
                </MenuItem>
                <MenuItem key="mensal_fixa" value="Mensal Fixa">
                  Mensal Fixa
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} lg={4}>
              <TextField
                id="tarifa.cobranca"
                variant="outlined"
                disabled={!handleEdit}
                name="tarifa.cobranca"
                label="Cobrança"
                value={maskRealMoney(formik.values.tarifa.cobranca)}
                onChange={(event) => {
                  formik.setFieldValue(
                    "tarifa.cobranca",
                    maskRealMoney(event.target.value)
                  );
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.tarifa?.cobranca &&
                  Boolean(formik.errors.tarifa?.cobranca)
                }
                helperText={
                  formik.touched.tarifa?.cobranca &&
                  formik.errors.tarifa?.cobranca
                }
                fullWidth
                inputProps={{
                  maxLength: 17,
                }}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <TextField
            id="observacao"
            variant="outlined"
            disabled={!handleEdit}
            name="tarifa.observacao"
            label="Observação"
            onChange={formik.handleChange}
            value={formik.values.tarifa.observacao}
            onBlur={formik.handleBlur}
            error={
              formik.touched.tarifa?.observacao &&
              Boolean(formik.errors.tarifa?.observacao)
            }
            helperText={
              formik.touched.tarifa?.observacao &&
              formik.errors.tarifa?.observacao
            }
            fullWidth
            required
          />
        </Grid>
      </Grid>

      <Grid item style={{ marginTop: "50px" }} justify="flex-end">
        <Grid container justify="flex-end" alignItems="center" spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              rel="noopener noreferrer"
              onClick={() => changeStep(2)}
            >
              <ArrowBack className={classes.arrowIconBack} />
              Anterior
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              rel="noopener noreferrer"
              onClick={() => changeStep(4)}
            >
              Próximo
              <ArrowForward className={classes.arrowIconNext} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Form;
