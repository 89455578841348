import React, { useEffect } from "react";
import { Button, Grid, TextField, MenuItem } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { NavLink as RouterLink } from "react-router-dom";
import Label from "../../../../../components/Label";

const status = [
  { status: "ATIVO", color: "info" },
  { status: "INATIVO", color: "error" },
  { status: "BLOQUEADO", color: "warning" },
  { status: "PENDENTE", color: "secondary" },
];

const Form = ({ formik }) => {
  return (
    <div>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} lg={3}>
              <TextField
                id="nome"
                name="nome"
                label="Nome Completo"
                onChange={formik.handleChange}
                value={formik.values.nome}
                onBlur={formik.handleBlur}
                error={formik.touched.nome && Boolean(formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
                required
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextField
                id="status"
                name="status"
                label="Status"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.status}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                fullWidth
                select
                required
              >
                {status.map(({ status, color }) => (
                  <MenuItem key={status} value={status}>
                    <Label color={color}>{status}</Label>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4}>
            <Grid item lg={3}>
              <TextField
                id="email"
                name="email"
                label="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to="/userList"
              >
                {" "}
                CANCELAR{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Save />}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Form;
