import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";

const ShopkeepersData = ({
  title = "",
  data = [],
  showButtons = false,
  textButtonOne = "",
  handleClickOne,
  IconButtonOne = undefined,
  textButtonTwo = "",
  IconButtonTwo = undefined,
  handleClickTwo,
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, "Dados do usuário")}
      variant="outlined"
      //   elevation={5}
    >
      <CardHeader
        title={
          <Typography
            variant="overline"
            display="block"
            gutterBottom
            className={classes.headerText}
          >
            {title}
          </Typography>
        }
        className={classes.header}
      />
      <Divider />
      <Table>
        <TableBody>
          {data.map(({ label, value }) => {
            return (
              <TableRow key={`${label}/${value}`}>
                <TableCell className={classes.fontWeightMedium}>
                  {label}
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    {value}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {showButtons && (
        <Box p={1} display="flex" flexDirection="column" alignItems="flex-end">
          {textButtonOne !== "" && (
            <Button
              variant="contained"
              color="primary"
              startIcon={IconButtonOne && <IconButtonOne />}
              onClick={handleClickOne}
            >
              {textButtonOne}
            </Button>
          )}
          {textButtonTwo !== "" && (
            <Button
              startIcon={IconButtonTwo && <IconButtonTwo />}
              onClick={handleClickTwo}
            >
              {textButtonTwo}
            </Button>
          )}
        </Box>
      )}
    </Card>
  );
};

export default ShopkeepersData;
