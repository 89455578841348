import React, { useState } from "react";
import PropTypes from "prop-types";
import Img from "../../assets/images/no-image-found-360x250.png";
import { AddPhotoAlternate } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import Label from "../Label";
import { useStyles } from "./styles";
// import { width } from "dom-helpers";

/**
 * Component to img. Use the funciton imgUpload if you send in request.
 * Formik is required
 */
const ImgUpload = ({
  name,
  category,
  formik,
  base64 = null,
  showButton = true,
  status = "",
  showDivOpacity = false,
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(showDivOpacity);
  const handleUploadClick = (event) => {
    event.currentTarget.files &&
      (document.getElementById(`${name}/img`).src = window.URL.createObjectURL(
        event.currentTarget.files[0]
      ));
    event.currentTarget.files[0].category = category;
    formik.setFieldValue(`${name}`, event.currentTarget.files[0]);
    setShow(false);
  };

  let labelStatus = "";

  const objStatus = {
    reprovado: "disapproved",
    aprovado: "approved",
    aguarandoaprovacao: "waitApproved",
  };

  if (status === null) {
    status = "";
  } else if (status === "Aguardando Aprovação") {
    status = "aguarandoAprovacao";
    labelStatus = "Aguardando Aprovação";
  } else {
    labelStatus = status;
  }
  return (
    <div className={classes.container}>
      {/* {show && (
        <div className={classes.divOpacity}>
          <Label color={objStatus[status.toLocaleLowerCase()]}>
            {labelStatus.toLocaleUpperCase()}
          </Label>
        </div>
      )} */}
      <div className={classes.img}>
        <img
          id={`${name}/img`}
          src={!base64 ? Img : base64}
          alt="Imagem carregada"
          className={classes.imgUpload}
        />

        <input
          name={name}
          accept="image/png, image/jpeg"
          className={classes.input}
          id={`${name}`}
          multiple
          type="file"
          onChange={handleUploadClick}
        />
        {showButton && (
          <label htmlFor={`${name}`}>
            <Fab
              component="span"
              className={(classes.button, classes.positionBtnUpload)}
            >
              <AddPhotoAlternate />
            </Fab>
          </label>
        )}
      </div>
    </div>
  );
};

ImgUpload.propTypes = {
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
};

export default ImgUpload;
