import Skeleton from "@material-ui/lab/Skeleton";

const SkeletonInfo = ({ width = 330 }) => {
  return (
    <div>
      <Skeleton width={width} />
      <Skeleton width={width} />
      <Skeleton width={width} />
      <Skeleton width={width} />
      <Skeleton width={width} />
    </div>
  );
};

export default SkeletonInfo;
