import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  Favorite,
  Share,
  ExpandMore,
  MoreVert,
  PersonOutline,
  LockOpenOutlined,
} from "@material-ui/icons";
import { Edit } from "react-feather";
import Label from "../../../../../components/Label";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useSelector } from "react-redux";

const UserInfo = ({ editUser }) => {
  const classes = useStyles();
  const user = useSelector((state) => {
    return state.user.currentUser[0];
  });
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row">
          <Grid item>
            {/* <Card className={clsx(classes.root, "Dados do usuário")} {...rest}> */}
            <Card
              className={clsx(classes.root, "Dados do usuário")}
              variant="outlined"
              //   elevation={5}
            >
              <CardHeader
                title={
                  <Typography variant="subtitle1">Dados do Usuário</Typography>
                }
              />
              <Divider />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Nome
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="textSecondary">
                        {user.nome}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Email
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="textSecondary">
                        {user.email}
                      </Typography>
                      <Label
                        color={
                          user.validacao === "email validado"
                            ? "success"
                            : "error"
                        }
                      >
                        {user.validacao === "email validado"
                          ? "Email verificado"
                          : "Email não verificado"}
                      </Label>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.fontWeightMedium}>
                      Status
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="textSecondary">
                        <Label color="info">{user.status}</Label>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* Usar no futuro para possiveis ações */}
              {/* <Box
                p={1}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Button startIcon={<LockOpenOutlined />}>
                  Reset &amp; Send Password
                </Button>
                <Button startIcon={<PersonOutline />}>Login as Customer</Button>
              </Box> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
