import { combineReducers } from "redux";
import userReducer from "./ducks/User";
import signerReducer from "./ducks/Signer";
import groupReducer from "./ducks/Group";
import validationReducer from "./ducks/Validation";
import shopkeeperReducer from "./ducks/Shopkeeper";
import statusReducer from "./ducks/Status";

const reducers = combineReducers({
  signer: signerReducer,
  user: userReducer,
  group: groupReducer,
  validation: validationReducer,
  shopkeeper: shopkeeperReducer,
  status: statusReducer,
});

export default reducers;
