import { Person, Group, LibraryAddCheck } from "@material-ui/icons";

export const sections = [
  {
    subheader: "Lojista",
    section: "shopkeeper",
    open: true,
    items: [
      {
        title: "Lojistas",
        icon: LibraryAddCheck,
        href: "/shopkeeperlist",
      },
    ],
  },
  {
    subheader: "Gestão de Usuários",
    section: "userSystem",
    open: true,
    items: [
      {
        title: "Usuários",
        icon: Person,
        href: "/userList",
      },
      {
        title: "Grupos",
        icon: Group,
        href: "/groupList",
      },
    ],
  },
];
