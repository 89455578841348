import React from "react";
import Page from "../../components/Page";
import Information from "./Iformation";

export const Setting = () => {
  return (
    <Page>
      <Information />
    </Page>
  );
};

export default Setting;
