import React from "react";
// import ArrowNavigate from "../../../../../components/ArrowNavegate";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const columnsData = [
  {
    name: "groupId",
    label: "ID",
    options: {
      filter: false,
      display: false,
      download: false,
      print: false,
      searchable: false,
      viewColumns: false,
    },
  },
  {
    name: "nome",
    label: "Nome",
    // options: {
    //   filterType: "textField",
    //   customFilterListOptions: { render: (v) => `Empresa: ${v}` },
    // },
  },

  {
    name: "Ações",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataindex) => {
        //   const dataRow = companies && companies[dataindex].companyId;
        //   return <ArrowNavigate linkTo={`/app/material/company/${dataRow}`} />;
        return (
          <IconButton onClick={() => {}}>
            <Delete />
          </IconButton>
        );
        // return <ArrowNavigate linkTo={`/userList`} />;
      },
    },
  },
];

export default columnsData;
