const countries = [
    "Andorra",
    "Emirados Árabes Unidos",
    "Afeganistão",
    "Antígua e Barbuda",
    "Albânia",
    "Armênia",
    "Angola",
    "Argentina",
    "Áustria",
    "Austrália",
    "Azerbaijão",
    "Bósnia e Herzegovina",
    "Barbados",
    "Bangladesh",
    "Bélgica",
    "Burkina Faso",
    "Bulgária",
    "Barein",
    "Burundi",
    "Benin",
    "Brunei",
    "Bolívia",
    "Brasil",
    "Bahamas",
    "Butão",
    "Botsuana",
    "Belarus",
    "Belize",
    "Canadá",
    "República Democrática do Congo",
    "República Centro Africana",
    "Congo",
    "Suíça",
    "Costa do Marfim",
    "Chile",
    "Camarões",
    "China",
    "Colômbia",
    "Costa Rica",
    "Cuba",
    "Cabo Verde",
    "Chipre",
    "República Tcheca",
    "Alemanha",
    "Djibouti",
    "Dinamarca",
    "Dominica",
    "República Dominicana",
    "Argélia",
    "Equador",
    "Estônia",
    "Egito",
    "Eritréia",
    "Espanha",
    "Etiópia",
    "Finlândia",
    "Fiji",
    "Micronésia",
    "França",
    "Gabão",
    "Reino Unido",
    "Granada",
    "Geórgia",
    "Gana",
    "Gâmbia",
    "Guiné",
    "Guiné Equatorial",
    "Grécia",
    "Guatemala",
    "Guiné-Bissau",
    "Guiana",
    "Honduras",
    "Croácia",
    "Haiti",
    "Hungria",
    "Indonésia",
    "Irlanda",
    "Israel",
    "Índia",
    "Iraque",
    "Irã",
    "Islândia",
    "Itália",
    "Jamaica",
    "Jordânia",
    "Japão",
    "Quênia",
    "Quirguistão",
    "Camboja",
    "Kiribati",
    "Comores",
    "São Cristóvão e Nevis",
    "República Popular Democrática da Coréia",
    "República da Coréia",
    "Kuwait",
    "Cazaquistão",
    "Laos",
    "Líbano",
    "Santa Lúcia",
    "Liechtenstein",
    "Sri Lanka",
    "Libéria",
    "Lesoto",
    "Lituânia",
    "Luxemburgo",
    "Letônia",
    "Líbia",
    "Marrocos",
    "Mônaco",
    "Moldávia",
    "Montenegro",
    "Madagáscar",
    "Ilhas Marshall",
    "Macedônia do Norte",
    "Mali",
    "Mianmar",
    "Mongólia",
    "Mauritânia",
    "Malta",
    "Maurício",
    "Maldivas",
    "Malauí",
    "México",
    "Malásia",
    "Moçambique",
    "Namíbia",
    "Níger",
    "Nigéria",
    "Nicarágua",
    "Holanda",
    "Noruega",
    "Nepal",
    "Nauru",
    "Nova Zelândia",
    "Omã",
    "Panamá",
    "Peru",
    "Papua Nova Guiné",
    "Filipinas",
    "Paquistão",
    "Polônia",
    "Portugal",
    "Palau",
    "Paraguai",
    "Catar",
    "Romênia",
    "Sérvia",
    "Rússia (Federação Russa)",
    "Ruanda",
    "Arábia Saudita",
    "Ilhas Salomão",
    "Seichelles",
    "Sudão",
    "Suécia",
    "Singapura",
    "Eslovênia",
    "Eslováquia",
    "Serra Leoa",
    "San Marino",
    "Senegal",
    "Somália",
    "Suriname",
    "Sudão do Sul",
    "São Tomé e Príncipe",
    "El Salvador",
    "Síria",
    "Eswatini",
    "Chade",
    "Togo",
    "Tailândia",
    "Tadjiquistão",
    "Timor Leste",
    "Turcomenistão",
    "Tunísia",
    "Tonga",
    "Turquia",
    "Trinidad e Tobago",
    "Tuvalu",
    "Tanzânia",
    "Ucrânia",
    "Uganda",
    "Estados Unidos da América",
    "Uruguai",
    "Uzbequistão",
    "São Vicente e Granadinas",
    "Venezuela",
    "Vietnã",
    "Vanuatu",
    "Samoa",
    "Iêmen",
    "África do Sul",
    "Zâmbia",
    "Zimbábue"
]

export default countries;