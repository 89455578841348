import { makeStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  //   root: {
  //     maxWidth: 345,
  //   },
  //   media: {
  //     height: 0,
  //     paddingTop: "56.25%", // 16:9
  //   },
  //   expand: {
  //     transform: "rotate(0deg)",
  //     marginLeft: "auto",
  //     transition: theme.transitions.create("transform", {
  //       duration: theme.transitions.duration.shortest,
  //     }),
  //   },
  //   expandOpen: {
  //     transform: "rotate(180deg)",
  //   },
  //   avatar: {
  //     backgroundColor: red[500],
  //   },

  //   rootList: {
  //     width: "100%",
  //     maxWidth: "36ch",
  //     backgroundColor: theme.palette.background.paper,
  //   },
  //   inline: {
  //     display: "inline",
  //   },
}));
