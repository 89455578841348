import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import {
  Container,
  Tab,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { CustomTab, useStyles } from "./styles";
import TabPanel from "./TabPanel";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreActions from "../../../../components/MoreActions";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Save } from "@material-ui/icons";
import {
  shopkeeperEditById,
  clearCurrentShopkeeper,
  documentsByUser,
  shopkeeperById,
} from "../../../../store/ducks/Shopkeeper";
import {
  statusByType,
  editStatusDocuments,
} from "../../../../store/ducks/Status";

import Page from "../../../../components/Page";
import AccountForm from "./AccountForm";
import CompanyForm from "./CompanyForm";
import Information from "./Information";
import PersonForm from "./PersonForm";
import SensediaForm from "./SensediaForm";
import TariffForm from "./TariffForm";
import AcquirerForm from "./AcquirerForm";
import Documents from "./Documents";
import { Edit } from "react-feather";
import { useWindowSize } from "../../../../utils/window/getHeight.js";
import { formatDate, maskToFloat } from "../../../../utils/string/masks";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const EditShopkeeper = () => {
  const WindowSize = useWindowSize();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [showTab, setShowTab] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [currentShopkeeper, setCurrentShopkeeper] = useState({});
  const {
    shopkeeper: { status, type, message, imgData },
    signer: { nome },
  } = useSelector((state) => state);
  console.log(currentShopkeeper?.usuario?.status);
  const handleEdit =
    currentShopkeeper?.usuario?.status === "PENDENTE DE INTEGRAÇÃO"
      ? false
      : true;
  const showPj = !!currentShopkeeper?.endereco_cnpj ? true : false;

  const validationSchema = yup.object({
    usuario: yup.object({
      nome: yup
        .string()
        .min(10, "Nome deve conter no minimo 10 caracteres")
        .required("Nome é obrigatório"),
      email: yup
        .string()
        .email("Insira um email valido")
        .required("Email é obrigatório"),
      status: yup.string().required("Status é obrigatório"),
    }),

    pessoa: yup.object({
      cpf: yup.string().required("CPF é obrigatório"),
      celular: yup.string().required("Celular é obrigatório"),
      nascimento: yup
        .string()
        .required("Nascimento é um campo obrigatório")
        .nullable(),
      naturalidade: yup
        .string()
        .required("Naturalidade é um campo obrigatório"),
      nacionalidade: yup
        .string()
        .required("Nacionalidade é um campo obrigatório"),
      rg: yup.string().required("RG é um campo obrigatório"),
      emissor: yup.string().required("Orgão emissor é obrigatório"),
      emissao: yup
        .string()
        .required("Data de emissão é obrigatório")
        .nullable(),
      sexo: yup.string().required("Sexo é um campo obrigatório"),
      estado_civil: yup.string().required("Estado civil é obrigatório"),
      mae: yup.string().required("Mãe é um campo obrigatório"),
      pai: yup.string().required("Pai é um campo obrigatório"),
    }),

    empresa:
      showPj &&
      yup.object({
        cnpj: yup.string().required("CNPJ é um campo obrigatório"),
        cnae: yup.string().required("CNAE é um campo obrigatório"),
        razao_social: yup
          .string()
          .required("Razão social é um campo obrigatório"),
        // telefone_fixo: yup
        //   .string()
        //   .required("Telefone fixo é um campo obrigatório"),
        // celular_pj: yup.string().required("Celular CNPJ é um campo obrigatório"),
        // telefone_fixo: yup.string().required("Telefone fixo é um campo obrigatório"),
        nome_fantasia: yup
          .string()
          .required("Nome fantasia é um campo obrigatório"),
        // site: yup.string().required("Site é um campo obrigatório"),
      }),

    endereco_cnpj:
      showPj &&
      yup.object({
        endereco: yup.string().required("Endereço é um campo obrigatório"),
        cep: yup.string().required("CEP é um campo obrigatório"),
        complemento: yup.string(),
        bairro: yup.string().required("Bairro é um campo obrigatório"),
        numero: yup.string().required("Numero é um campo obrigatório"),
        cidade: yup.string().required("Cidade é um campo obrigatório"),
        estado: yup.string().required("Estado é um campo obrigatório"),
      }),

    endereco_cpf: yup.object({
      endereco: yup.string().required("Endereço é um campo obrigatório"),
      cep: yup.string().required("CEP é um campo obrigatório"),
      complemento: yup.string(),
      bairro: yup.string().required("Bairro é um campo obrigatório"),
      numero: yup.string().required("Numero é um campo obrigatório"),
      cidade: yup.string().required("Cidade é um campo obrigatório"),
      estado: yup.string().required("Estado é um campo obrigatório"),
    }),

    conta: yup.object({
      banco: yup.string().required("Estado é um campo obrigatório"),
      agencia: yup.string().required("Agência é um campo obrigatório"),
      conta: yup.string().required("Conta é um campo obrigatório"),
      pix: yup.string().required("Pix é um campo obrigatório"),
    }),

    tarifa: yup.object({
      risco: yup
        .string()
        .required("Risco é um campo obrigatório")
        .trim()
        .nullable(),
      periodo: yup
        .string()
        .required("Período é um campo obrigatório")
        .trim()
        .nullable(),
      observacao: yup
        .string()
        .required("Observação é um campo obrigatório")
        .trim()
        .nullable(),
      segmento: yup
        .string()
        .required("Segmento é um campo obrigatório")
        .trim()
        .nullable(),
      tipo_cobranca: yup
        .string()
        .required("Tipo cobrança por transação é um campo obrigatório")
        .trim()
        .nullable(),
      faturamento: yup
        .string()
        .required("Faturamento é um campo obrigatório")
        .trim()
        .nullable(),
      cobranca: yup
        .string()
        .required("Cobrança é um campo obrigatório")
        .nullable(),
    }),
  });

  useEffect(() => {
    if (status === "completed" && type === "editUser") {
      enqueueSnackbar(message, {
        variant: "success",
      });
    } else if (status === "failed" && type === "editUser") {
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }, [status]);

  useEffect(() => {
    if (status === "loading") {
      setOpen(true);
    } else if (status === "completed") {
      setOpen(false);
    } else {
      setOpen(false);
    }
  }, [status]);

  useEffect(() => {
    shopkeeperById(id);
    dispatch(statusByType("documentos"));
    dispatch(documentsByUser(id));
    return () => {
      dispatch(clearCurrentShopkeeper());
    };
  }, []);

  const images = {};
  imgData instanceof Array &&
    imgData.forEach(({ categoria, status }) => {
      status = status ?? "PENDENTE DE DOCUMENTAÇÃO";
      images[categoria] = status;
    });

  const createObjStatusPUT = (documentos) => {
    return imgData.map(({ id, categoria }) => {
      return {
        id,
        usuario: nome,
        status: documentos[categoria],
      };
    });
  };

  const formik = useFormik({
    initialValues: {
      ...currentShopkeeper,
      documentos: {
        ...images,
      },
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const newValue = {
        ...values,
        pessoa: {
          ...values.pessoa,
          emissao: formatDate(values.pessoa.emissao),
          nascimento: formatDate(values.pessoa.nascimento),
        },
        tarifa: {
          ...values.tarifa,
          faturamento: maskToFloat(values.tarifa.faturamento),
          cobranca: maskToFloat(values.tarifa.cobranca),
        },
      };
      console.log("Novo valor");
      console.log(newValue);
      delete newValue.documentos;
      dispatch(await shopkeeperEditById(newValue));
      dispatch(editStatusDocuments(createObjStatusPUT(values.documentos)));
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleEditShopkeeper = async (shopkeeper) => {
    console.log(shopkeeper);
    await setCurrentShopkeeper(shopkeeper);
  };

  const ChangeStep = (v) => {
    setValue(v);
  };

  const actions = [
    {
      icon: <Save />,
      name: "Salvar",
      handleClick: () => {
        formik.handleSubmit();
      },
      hidden: !showTab,
    },
    {
      icon: <Edit />,
      name: "Editar",
      handleClick: () => {
        setShowTab(!showTab);
      },
      hidden: showTab,
    },
  ];
  return (
    <Page>
      <Container maxWidth="xl">
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {showTab ? (
          <Card
            style={{ height: WindowSize.height - 150, overflowY: "scroll" }}
          >
            <CardContent>
              <form>
                <Box
                  style={{ maxWidth: WindowSize.width - 450, minWidth: 220 }}
                >
                  <CustomTab
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    // aria-label="User Tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable"
                  >
                    <Tab label="Representante Legal" {...a11yProps(0)} />
                    <Tab
                      style={{ display: showPj ? "" : "none" }}
                      label="Pessoa Jurídica"
                      {...a11yProps(1)}
                    />
                    <Tab label="Dados Bancários" {...a11yProps(2)} />
                    <Tab label="Risco e Tarifação" {...a11yProps(3)} />
                    <Tab label="Adquirente" {...a11yProps(4)} />
                    <Tab label="Sensedia" {...a11yProps(5)} />
                    <Tab label="Documentos" {...a11yProps(6)} />
                  </CustomTab>
                </Box>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Box mt={3}>
                      <PersonForm
                        handleEdit={handleEdit}
                        changeStep={ChangeStep}
                        formik={formik}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Box mt={3}>
                      <CompanyForm
                        handleEdit={handleEdit}
                        changeStep={ChangeStep}
                        formik={formik}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <Box mt={3}>
                      <AccountForm
                        handleEdit={handleEdit}
                        changeStep={ChangeStep}
                        formik={formik}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={3} dir={theme.direction}>
                    <Box mt={3}>
                      <TariffForm
                        handleEdit={handleEdit}
                        changeStep={ChangeStep}
                        formik={formik}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={4} dir={theme.direction}>
                    <Box mt={3}>
                      <AcquirerForm
                        handleEdit={handleEdit}
                        changeStep={ChangeStep}
                        formik={formik}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={5} dir={theme.direction}>
                    <Box mt={3}>
                      <SensediaForm
                        handleEdit={handleEdit}
                        changeStep={ChangeStep}
                        formik={formik}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={6} dir={theme.direction}>
                    <Box mt={3}>
                      <Documents formik={formik} />
                    </Box>
                  </TabPanel>
                </SwipeableViews>
              </form>
            </CardContent>
          </Card>
        ) : (
          <Information editShopkeeper={handleEditShopkeeper} />
        )}
        <MoreActions actions={actions} />
      </Container>
    </Page>
  );
};

export default EditShopkeeper;
