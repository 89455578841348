import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Backdrop,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import sha256 from "crypto-js/sha256";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { maskCpf, maskCel } from "../../utils/string/masks";
import {
  finishRegister,
  getValidatedEmail,
  clearUser,
} from "../../store/ducks/User";
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FinishRegister() {
  const { base64 } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const state = useSelector((state) => state);
  const decodedData = Buffer.from(base64, "base64");

  let dateNow = new Date();
  let dateExpiration = Date.parse(JSON.parse(decodedData).dateExpiration);
  let difference =
    Date.parse(JSON.parse(decodedData).dateExpiration) - Date.parse(dateNow);

  const validationSchema = yup.object({
    name: yup.string().required("O campo nome é obrigatório"),
    email: yup
      .string()
      .email("Não é um email valido")
      .required("O campo email é obrigatório"),
    password: yup
      .string()
      .required("O campo senha é obrigatório")
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        `A senha deve conter mínimo de oito caracteres, pelo menos, uma letra maiúscula, uma letra minúscula, números e um caractere especial`
      )
      .trim(),
    confirmPassword: yup
      .string()
      .required("O campo confirmação de senha é obrigatório")
      .oneOf([yup.ref("password"), null], "Senhas devem ser iguais."),
    cpf: yup.string().required("O campo CPF é obrigatório"),
    celphone: yup.string(),
    ramal: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: JSON.parse(decodedData).nome,
      email: JSON.parse(decodedData).email,
      status: JSON.parse(decodedData).status,
      password: "",
      confirmPassword: "",
      cpf: "",
      celphone: "",
      ramal: "",
    },
    validationSchema,
    onSubmit: async ({
      name,
      email,
      password,
      confirmPassword,
      cpf,
      celphone,
      ramal,
    }) => {
      const body = {
        id: JSON.parse(decodedData).id,
        nome: name,
        email,
        senha: sha256(password).toString().trim(),
        cpf,
        celular: celphone,
        ramal,
      };
      dispatch(finishRegister(body));
    },
  });

  useEffect(() => {
    dispatch(clearUser());
    dispatch(getValidatedEmail(formik.values.email));
  }, []);

  useEffect(() => {
    if (state.user.status === "completed") {
      setOpen(false);

      if (
        state.user.statusMessage === "success" &&
        state.user.type === "finishRegister"
      ) {
        setOpenDialog(true);
      }

      enqueueSnackbar(state.user.message, {
        variant: state.user.statusMessage,
      });
    } else if (state.user.status === "loading") {
      setOpen(true);
    } else if (state.user.status === "failed") {
      setOpen(false);
      enqueueSnackbar(state.user.message, {
        variant: state.user.statusMessage,
      });
    }
  }, [state.user.status]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRedirect = () => {
    history.push("/signin");
  };

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="primary" />
      </Backdrop>

      <Dialog
        open={difference < 0 || state.user.message === "email validado"}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {/* {"Há algo de errado com seu link de conclusão cadastral"} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {state.user.message === "email validado"
              ? `Atualização de dados já realizada!`
              : `Seu link de conclusão do cadastro expirou, não é possível 
                completar seus dados, o link é válido somente 24 horas após
                o recebimento do e-mail.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRedirect} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Resultado da requisição de atualização do usuário."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {state.user.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRedirect} variant="contained" color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Complete seu cadastro:
        </Typography>

        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            autoFocus
            fullWidth
            id="name"
            label="Nome"
            name="name"
            onBlur={formik.handleBlur}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cpf"
            label="CPF"
            name="cpf"
            onBlur={formik.handleBlur}
            value={formik.values.cpf}
            onChange={(e) => {
              formik.setFieldValue(e.target.id, maskCpf(e.target.value));
            }}
            error={formik.touched.cpf && Boolean(formik.errors.cpf)}
            inputProps={{ maxLength: 14 }}
            helperText={formik.touched.cpf && formik.errors.cpf}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="celphone"
            name="celphone"
            label="Celular"
            value={formik.values.celphone}
            onChange={(e) =>
              formik.setFieldValue(e.target.id, maskCel(e.target.value))
            }
            onBlur={formik.handleBlur}
            error={formik.touched.celphone && Boolean(formik.errors.celphone)}
            helperText={formik.touched.celphone && formik.errors.celphone}
            inputProps={{ maxLength: 15 }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="ramal"
            label="Ramal"
            name="ramal"
            onBlur={formik.handleBlur}
            value={formik.values.ramal}
            onChange={formik.handleChange}
            error={formik.touched.ramal && Boolean(formik.errors.ramal)}
            helperText={formik.touched.ramal && formik.errors.ramal}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            onBlur={formik.handleBlur}
            label="Senha"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            onBlur={formik.handleBlur}
            label="Confirmação de senha"
            type={showPassword ? "text" : "password"}
            id="confirmPassword"
            autoComplete="current-password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Concluir
          </Button>
        </form>
      </div>
    </Container>
  );
}
