import React, { useEffect, useState } from "react";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { GroupAdd } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import columnsData from "./ColumnsData";
import { useSelector, useDispatch } from "react-redux";
import { listGroups } from "../../../../../store/ducks/Group";
import TransitionsModal from "../../../../../components/Modal";
import AutoCompleteInput from "../../../../../components/AutoCompleteInput";

const options = {
  filterType: "checkbox",
};

export const RelatedGroupList = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(listGroups());
  }, [dispatch]);

  const handleModal = (event) => {
    setOpenModal(!openModal);
  };

  const state = useSelector((state) => state);
  const listGroupsData = state.group.data;

  const selectedRelated = state.user.selectedRelatedGroups;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TransitionsModal openModal={openModal} handleModal={handleModal}>
          <AutoCompleteInput
            data={listGroupsData}
            selectedValue={selectedRelated}
          />
        </TransitionsModal>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Tooltip title="Adicionar grupo ao usuário">
              <Button color="primary" variant="contained" onClick={handleModal}>
                <GroupAdd />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MUIDataTable
          title={"Grupos do usuário"}
          data={selectedRelated}
          columns={columnsData}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default RelatedGroupList;
