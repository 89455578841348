import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const CustomTab = withStyles({
  indicator: {
    backgroundColor: "#005882",
  },
})(Tabs);
