import React, { useEffect, useState, useMemo } from "react";
import MUIDataTable from "mui-datatables";
import {
  CircularProgress,
  Backdrop,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Icon from "@mdi/react";
import {
  mdiAccountClock,
  mdiFileClock,
  mdiFileCheck,
  mdiConnection,
} from "@mdi/js";
import getColumns from "./ColumnsData";
import { useSelector, useDispatch } from "react-redux";
import { shopkeeperList } from "../../../../../store/ducks/Shopkeeper";
import { useStyles } from "./styles";

export const List = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    shopkeeper: { status: shopkeeperListStatus, data },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(shopkeeperList());
  }, []);

  useEffect(() => {
    if (shopkeeperListStatus === "completed") {
      setOpen(false);
    } else if (shopkeeperListStatus === "loading") {
      setOpen(true);
    } else if (shopkeeperListStatus === "failed") {
      setOpen(false);
    }
  }, [shopkeeperListStatus]);

  const columnsData = useMemo(() => {
    return getColumns(data);
  }, [data]);

  const CustomToolBarButtons = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Lista de Lojistas</Typography>
        </div>
        <div>
          <Tooltip title="AGUARDANDO APROVAÇÃO" aria-label="agProg">
            <IconButton
              // style={{ color: "#4994F8", backgroundColor: "#005882" }}
              className={classes.margin}
              onClick={() => {
                dispatch(shopkeeperList("AGUARDANDO APROVAÇÃO"));
              }}
            >
              <Icon
                path={mdiAccountClock}
                title="User Profile"
                size={1}
                className={classes.colorButtonHeader}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="PENDENTE DE INTEGRAÇÃO" aria-label="pendInt">
            <IconButton
              className={classes.margin}
              onClick={() => {
                dispatch(shopkeeperList("PENDENTE DE INTEGRAÇÃO"));
              }}
            >
              <Icon
                path={mdiConnection}
                title="User Profile"
                size={1}
                className={classes.colorButtonHeader}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="PENDENTE DE DOCUMENTAÇÃO" aria-label="penDoc">
            <IconButton
              className={classes.margin}
              onClick={() => {
                dispatch(shopkeeperList("PENDENTE DE DOCUMENTAÇÃO"));
              }}
            >
              <Icon
                path={mdiFileClock}
                title="User Profile"
                size={1}
                className={classes.colorButtonHeader}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="DOCUMENTAÇÃO APROVADA" aria-label="docAp">
            <IconButton
              className={classes.margin}
              onClick={() => {
                dispatch(shopkeeperList("DOCUMENTAÇÃO APROVADA"));
              }}
            >
              <Icon
                path={mdiFileCheck}
                title="User Profile"
                size={1}
                className={classes.colorButtonHeader}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="primary" />
      </Backdrop>
      <MUIDataTable
        title={<CustomToolBarButtons />}
        data={data}
        columns={columnsData}
        // options={options}
      />
    </>
  );
};

export default List;
