import React from "react";
import { useHistory } from "react-router";
import { Menu, MenuItem, IconButton, Badge } from "@material-ui/core";
import {
  AccountCircle,
  ExitToApp,
  Settings,
  Mail,
  Notifications,
} from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { logOut } from "../../store/ducks/Signer";

const MenuButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    history.push("/signin");
  };

  const menu = [
    {
      label: "Configurações",
      func: () => {
        history.push("/setting");
      },
      icon: Settings,
    },
    {
      label: "Sair",
      func: handleLogout,
      icon: ExitToApp,
    },
  ];

  return menu.map(({ label, func, icon: Icon }, index) => {
    return (
      <MenuItem key={index} onClick={func}>
        <IconButton
          aria-label={label}
          aria-controls={`primary-${label}`}
          aria-haspopup="true"
          color="inherit"
        >
          <Icon />
        </IconButton>
        <p>{label}</p>
      </MenuItem>
    );
  });
};

export default MenuButton;
