import React, { useEffect, useState, useMemo } from "react";
import MUIDataTable from "mui-datatables";
import { CircularProgress, Backdrop } from "@material-ui/core";
import getColumns from "./ColumnsData";
import { useSelector, useDispatch } from "react-redux";
import {
  userList,
  userStatus as getStatus,
} from "../../../../../store/ducks/User";
import { useStyles } from "./styles";
const options = {
  // filterType: "checkbox",
  filter: false,
};

export const List = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    user: { status: userListStatus, data, type },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(userList());
    dispatch(getStatus());
  }, []);

  useEffect(() => {
    if (userListStatus === "completed" && type === "userStatus") {
      setOpen(false);
    } else if (userListStatus === "failed" && type === "userStatus") {
      setOpen(false);
    } else if (userListStatus === "loading" && type === "userStatus") {
      setOpen(true);
    }
    return () => {};
  }, [userListStatus]);

  useEffect(() => {
    if (userListStatus === "completed") {
      setOpen(false);
    } else if (userListStatus === "loading") {
      setOpen(true);
    } else if (userListStatus === "failed") {
      setOpen(false);
    }
  }, [userListStatus]);

  const columnsData = useMemo(() => {
    return getColumns(data);
  }, [data]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="primary" />
      </Backdrop>
      <MUIDataTable
        title={"Lista de Usuários"}
        data={data}
        columns={columnsData}
        options={options}
      />
    </>
  );
};

export default List;
