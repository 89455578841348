import { createTheme } from "@material-ui/core";

//para criar tema, verificar https://material-ui.com/pt/customization/default-theme/
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#005882",
    },
    // secondary: purple,
    type: "dark",
  },
});

export default darkTheme;
