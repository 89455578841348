import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getShopkeeper,
  getShopkeeperById,
  editShopkeeperById,
  getShopkeeperStatus,
  getDocumentsByUser,
} from "./service";

export const shopkeeperList = createAsyncThunk(
  "shopkeeper/shopkeeperList",
  async (status, { rejectWithValue }) => {
    try {
      const { data } = await getShopkeeper(status);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const shopkeeperById = createAsyncThunk(
  "shopkeeper/shopkeeperById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getShopkeeperById(id);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const shopkeeperEditById = createAsyncThunk(
  "shopkeeper/shopkeeperEditById",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await editShopkeeperById(body);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const shopkeeperStatus = createAsyncThunk(
  "shopkeeper/shopkeeperStatus",
  async (_, { rejectWithValue, getState }) => {
    const {
      signer: { token },
    } = getState();
    try {
      const { data } = await getShopkeeperStatus(token);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const documentsByUser = createAsyncThunk(
  "user/documentsByUser",
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        signer: { token },
      } = getState();
      const payload = {
        id,
        token,
      };
      const { data } = await getDocumentsByUser(payload);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: [],
  status: "idle",
  currentShopkeeper: [],
  message: "",
  type: "",
  shopkeeperStatus: [],
  imgData: [],
};

const shopkeeper = createSlice({
  name: "shopkeeper",
  initialState,

  reducers: {
    clearCurrentShopkeeper(state, action) {
      return (state = {
        ...state,
        currentShopkeeper: [],
        message: "",
        status: "idle",
        type: "",
      });
    },
    clearImgUpload: (state) => {
      return (state = { ...state, percentUploadImg: 0, imgData: [] });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(shopkeeperList.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(shopkeeperList.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          data: action.payload?.message,
        });
      })
      .addCase(shopkeeperList.rejected, (state, action) => {
        return (state = { ...state, status: "failed" });
      })
      .addCase(shopkeeperById.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(shopkeeperById.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          currentShopkeeper: action.payload?.message,
        });
      })
      .addCase(shopkeeperById.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          message: action.payload?.message,
        });
      })
      .addCase(shopkeeperEditById.pending, (state) => {
        return (state = { ...state, status: "loading", type: "editUser" });
      })
      .addCase(shopkeeperEditById.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          message: "Usuário atualizado com sucesso",
          type: "editUser",
        });
      })
      .addCase(shopkeeperEditById.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          message: action.payload?.message,
          type: "editUser",
        });
      })
      .addCase(shopkeeperStatus.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(shopkeeperStatus.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          shopkeeperStatus: action.payload?.message,
        });
      })
      .addCase(shopkeeperStatus.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          shopkeeperStatus: action.payload?.message,
        });
      })
      .addCase(documentsByUser.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(documentsByUser.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          imgData: action.payload?.message,
        });
      })
      .addCase(documentsByUser.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          imgData: action.payload?.message,
        });
      });
  },
});

export const { clearCurrentShopkeeper, clearImgUpload } = shopkeeper.actions;
export default shopkeeper.reducer;
