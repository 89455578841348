import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
  dividerWidth: {
    width: "100%",
  },
  dividerHeight: {
    height: "325px",
    // flex: 1,
    // display: "flex",
  },
  arrowIconNext: {
    marginLeft: 10,
  },
  arrowIconBack: {
    marginRight: 10,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    minHeight: "100vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fieldCentralization: {
    marginTop: "0px",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
      borderRadius: "10px",
    },
  },
}));

export const CustomTab = withStyles({
  indicator: {
    backgroundColor: "#005882",
  },
})(Tabs);
