import React from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import Label from "../../../../../components/Label";
import { NavLink as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Form = ({ formik, readOnlyButton }) => {
  const { userStatus: status } = useSelector((state) => {
    return state.user;
  });
  return (
    <div>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} lg={3}>
              <TextField
                id="name"
                name="nome"
                label="Nome Completo"
                onChange={formik.handleChange}
                value={formik.values.nome}
                onBlur={formik.handleBlur}
                error={formik.touched.nome && Boolean(formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
                required
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <TextField
                id="status"
                name="status"
                label="Status"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.status}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                fullWidth
                select
                required
              >
                {status.map(({ status, info }) => (
                  <MenuItem key={status} value={status}>
                    <Label color={info}>{status}</Label>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4}>
            <Grid item lg={3}>
              <TextField
                id="email"
                name="email"
                label="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Form;
