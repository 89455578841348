import React, { useEffect } from "react";
import { Button, Grid, TextField, MenuItem } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { NavLink as RouterLink } from "react-router-dom";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import Label from "../../../../../components/Label";
import { useStyles } from "../styles";

const Form = ({ formik, changeStep, handleEdit }) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} lg={6}>
              <TextField
                id="client_id"
                variant="outlined"
                disabled={handleEdit}
                name="usuario.client_id"
                label="Client ID"
                onChange={formik.handleChange}
                value={formik.values.usuario.client_id}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.usuario?.client_id &&
                  Boolean(formik.errors.usuario?.client_id)
                }
                helperText={
                  formik.touched.usuario?.client_id &&
                  formik.errors.usuario?.client_id
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                id="client_secret"
                variant="outlined"
                disabled={handleEdit}
                name="usuario.cliente_secret"
                label="Client Secret"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.usuario.cliente_secret}
                error={
                  formik.touched.usuario?.cliente_secret &&
                  Boolean(formik.errors.usuario?.cliente_secret)
                }
                helperText={
                  formik.touched.usuario?.cliente_secret &&
                  formik.errors.usuario?.cliente_secret
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="base_64"
            variant="outlined"
            disabled={handleEdit}
            name="usuario.base_64"
            label="Base 64"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.usuario.base_64}
            error={
              formik.touched.usuario?.base_64 &&
              Boolean(formik.errors.usuario?.base_64)
            }
            helperText={
              formik.touched.usuario?.base_64 && formik.errors.usuario?.base_64
            }
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "100px" }} justify="flex-end">
        <Grid container justify="flex-end" alignItems="center" spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              rel="noopener noreferrer"
              onClick={() => changeStep(4)}
            >
              <ArrowBack className={classes.arrowIconBack} />
              Anterior
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Form;
