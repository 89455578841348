import { api } from "../../../services/api/api";

const getShopkeeper = async (status = "PENDENTE DE DOCUMENTAÇÃO") => {
  const res = await api.get(`/shopkeepers?status='${status}'`);
  return res;
};

const getShopkeeperById = async (id) => {
  const res = await api.get(`/shopkeepers/${id}`);
  return res;
};

const editShopkeeperById = async (body) => {
  const res = await api.put(`/shopkeepers`, body);
  return res;
};

const getShopkeeperStatus = async (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
  const res = await api.get("/status/usuario");
  return res;
};

const getDocumentsByUser = async ({ id, token }) => {
  const res = await api.get(`documents/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export {
  getShopkeeper,
  getShopkeeperById,
  editShopkeeperById,
  getShopkeeperStatus,
  getDocumentsByUser,
};
