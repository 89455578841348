import React from "react";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import { SpeedDial, SpeedDialIcon } from "@material-ui/lab";
// import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PropTypes from "prop-types";

export default function MoreActions({ actions }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const [hidden, setHidden] = React.useState(false);

  // const handleVisibility = () => {
  //   setHidden((prevHidden) => !prevHidden);
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        // hidden={hidden}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map(
          ({ name, icon, handleClick, hidden = true }) =>
            !hidden && (
              <SpeedDialAction
                delay={2}
                key={name}
                icon={icon}
                tooltipTitle={name}
                onClick={handleClick}
              />
            )
        )}
      </SpeedDial>
    </div>
  );
}

MoreActions.propTypes = {
  arrayWithShape: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    })
  ),
};
