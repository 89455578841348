import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { GroupAdd } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import columnsData from "./ColumnsData";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { listGroups } from "../../../../../store/ducks/Group";
import {
  clearUser,
  relatedGroups,
  addSelectedGroups,
} from "../../../../../store/ducks/User";
import TransitionsModal from "../../../../../components/Modal";
import AutoCompleteInput from "../../../../../components/AutoCompleteInput";

const options = {
  filterType: "checkbox",
};

export const RelatedGroupList = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const state = useSelector((state) => state);
  const reletadGroupsUser = state.user.relatedGroups;

  useEffect(() => {
    // dispatch(listGroups());
    dispatch(relatedGroups(id));

    return () => {
      dispatch(clearUser());
    };
  }, []);

  useEffect(() => {
    dispatch(addSelectedGroups(reletadGroupsUser));
  }, [reletadGroupsUser]);

  const handleModal = (event) => {
    dispatch(listGroups());
    setOpenModal(!openModal);
  };

  const listGroupsData = state.group.data;
  const selectedRelated = state.user.selectedRelatedGroups;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TransitionsModal openModal={openModal} handleModal={handleModal}>
          <AutoCompleteInput
            data={listGroupsData}
            selectedValue={selectedRelated}
          />
        </TransitionsModal>
        <Grid container justifyContent="flex-end">
          <Button color="primary" variant="contained" onClick={handleModal}>
            <GroupAdd />
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <MUIDataTable
          title={"Grupos do usuário"}
          data={selectedRelated}
          columns={columnsData}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default RelatedGroupList;
