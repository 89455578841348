import React from "react";
import { PersonAdd } from "@material-ui/icons";
import Page from "../../../../components/Page";
import List from "./List";
// import { routes } from "../../../../routes/routes";
import { useSelector } from "react-redux";

export const ShopkeeperList = () => {
  return (
    <Page>
      <List />
    </Page>
  );
};
export default ShopkeeperList;
