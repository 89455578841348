import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../../../store/ducks/Signer";

const Information = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    signer: { theme },
  } = useSelector((state) => state);
  console.log(theme);
  const [switchButton, setSwitchButton] = useState({
    themeSwitch: theme,
  });
  console.log("Switch");
  console.log(switchButton);

  useEffect(() => {
    setSwitchButton({
      ...switchButton,
      themeSwitch: theme,
    });
  }, [theme]);

  const handleChangeSwitch = () => {
    dispatch(changeTheme());
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="caption table">
        {/* <caption>A basic table example with a caption</caption> */}
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography variant="h6">Descrição</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h6">Ação</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">
              <Typography variant="overline">Tema Escuro</Typography>
            </TableCell>
            <TableCell align="left">
              <Switch
                checked={switchButton.themeSwitch}
                onChange={handleChangeSwitch}
                name="themeSwitch"
                color="primary"
                // inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </TableCell>
          </TableRow>
          {/* {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Information;
