import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, MenuItem } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { NavLink as RouterLink } from "react-router-dom";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import Label from "../../../../../components/Label";
import { useStyles } from "../styles";

import { bancos } from "./bank";

const Form = ({ formik, changeStep, handleEdit }) => {
  const classes = useStyles();
  const [isCaixa, setIsCaixa] = useState("hidden");

  const handleBank = (event) => {
    if (event.target.value === 104) {
      setIsCaixa("visible");
    } else {
      setIsCaixa("hidden");
    }
    formik.handleChange(event);
  };

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} lg={3}>
              <TextField
                id="banco"
                variant="outlined"
                disabled={!handleEdit}
                name="conta.banco"
                select
                label="Banco"
                value={formik.values.conta.banco}
                onChange={handleBank}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.conta?.banco &&
                  Boolean(formik.errors.conta?.banco)
                }
                helperText={
                  formik.touched.conta?.banco && formik.errors.conta?.banco
                }
                fullWidth
                required
              >
                {bancos.map(({ nomeBanco, numeroBanco }) => {
                  return (
                    <MenuItem key={numeroBanco} value={numeroBanco}>
                      {nomeBanco}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>            
            <Grid item xs={12} lg={4}>
              <TextField
                id="conta"
                variant="outlined"
                disabled={!handleEdit}
                name="conta.conta"
                label="Conta"
                onChange={formik.handleChange}
                value={formik.values.conta.conta}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.conta?.conta &&
                  Boolean(formik.errors.conta?.conta)
                }
                helperText={
                  formik.touched.conta?.conta && formik.errors.conta?.conta
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                id="agencia"
                variant="outlined"
                disabled={!handleEdit}
                name="conta.agencia"
                label="Agência"
                onChange={formik.handleChange}
                value={formik.values.conta.agencia}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.conta?.agencia &&
                  Boolean(formik.errors.conta?.agencia)
                }
                helperText={
                  formik.touched.conta?.agencia && formik.errors.conta?.agencia
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box visibility={isCaixa}>
                <TextField
                  variant="outlined"
                  disabled={!handleEdit}
                  name="conta.operacao"
                  label="Operação"
                  value={formik.values.conta.operacao}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  error={
                    formik.touched.conta?.operacao &&
                    Boolean(formik.errors.conta?.operacao)
                  }
                  helperText={
                    formik.touched.conta?.operacao &&
                    formik.errors.conta?.operacao
                  }
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <TextField
            id="pix"
            variant="outlined"
            disabled={!handleEdit}
            name="conta.pix"
            label="Pix"
            onChange={formik.handleChange}
            value={formik.values.conta.pix}
            onBlur={formik.handleBlur}
            error={
              formik.touched.conta?.pix && Boolean(formik.errors.conta?.pix)
            }
            helperText={formik.touched.conta?.pix && formik.errors.conta?.pix}
            fullWidth
            required
          />
        </Grid>
        <Grid
          item
          md={12}
          style={{ marginLeft: "-20px", marginTop: "100px" }}
          justify="flex-end"
          spacing={12}
        >
          <Grid container justify="flex-end" alignItems="center" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="sm"
                rel="noopener noreferrer"
                onClick={() => changeStep(1)}
              >
                <ArrowBack className={classes.arrowIconBack} />
                Anterior
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                rel="noopener noreferrer"
                onClick={() => changeStep(3)}
              >
                Próximo
                <ArrowForward className={classes.arrowIconNext} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Form;
