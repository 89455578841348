import { api } from "../../../services/api/api";

const getStatus = async (type) => {
  const res = await api.get(`/status/${type}`);
  return res;
};

const putStatusDocuments = async (body) => {
  const res = await api.put(`documents/`, body);
  return res;
};

export { getStatus, putStatusDocuments };
