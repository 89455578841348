import React from "react";
import MUIDataTable from "mui-datatables";
import columnsData from "./ColumnsData";

// const columns = ["Name", "Company", "City", "State"];

// const data = [
//   ["Joe James", "Test Corp", "Yonkers", "NY"],
//   ["John Walsh", "Test Corp", "Hartford", "CT"],
//   ["Bob Herm", "Test Corp", "Tampa", "FL"],
//   ["James Houston", "Test Corp", "Dallas", "TX"],
// ];

const data = [["Joe James"], ["John Walsh"], ["Bob Herm"], ["James Houston"]];

const options = {
  filterType: "checkbox",
};

export const List = () => {
  return (
    <MUIDataTable
      title={"Lista de Grupos"}
      data={data}
      columns={columnsData}
      options={options}
    />
  );
};

export default List;
