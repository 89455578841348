import Signin from "../pages/Signin";
import FinishRegister from "../pages/FinishRegister";
import RecoveryPassword from "../pages/RecoveryPassword";
import Home from "../pages/Home";
import ShopkeeperList from "../pages/Shopkeeper/Shopkeepers/ListView";
import UserList from "../pages/UserSystem/User/ListView";
import User from "../pages/UserSystem/User/FormView";
import GroupList from "../pages/UserSystem/Group/ListView";
import EditUser from "../pages/UserSystem/User/EditView";
import EditShopkeeper from "../pages/Shopkeeper/Shopkeepers/EditView";
import Setting from "../pages/Setting";

export const routes = [
  {
    path: "/finishregister/:base64",
    title: "Finish Register",
    component: FinishRegister,
    private: false,
  },
  {
    path: "/recoverypassword/:base64",
    title: "Recovery Password",
    component: RecoveryPassword,
    private: false,
  },
  { path: "/signin", title: "Signin", component: Signin, private: false },
  { path: "/dashboard", title: "Home", component: Home, private: true },
  { path: "/userList", title: "Usuario", component: UserList, private: true },
  {
    path: "/userConfig",
    title: "Configuração de Usuário",
    component: User,
    private: true,
  },
  { path: "/groupList", title: "Grupo", component: GroupList, private: true },
  {
    path: "/shopkeeperlist",
    title: "Loginsta",
    component: ShopkeeperList,
    private: true,
  },
  {
    path: "/editUser/:id",
    title: "EditUser",
    component: EditUser,
    private: true,
  },
  {
    path: "/editShopkeeper/:id",
    title: "EditShopkeeper",
    component: EditShopkeeper,
    private: true,
  },
  {
    path: "/setting",
    title: "Setting",
    component: Setting,
    private: true,
  },
];
