import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logOut } from "../../store/ducks/Signer";
export const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  useEffect(() => {
    if (!!state.signer.token) {
      history.push("/signin");
    }
    return () => {
      dispatch(logOut());
    };
  }, []);
  return <div>Home</div>;
};

export default Home;
