import React from "react";
import ArrowNavigate from "../../../../../components/ArrowNavegate";

const columnsData = [
  {
    name: "groupId",
    label: "ID",
    options: {
      filter: false,
      display: false,
      download: false,
      print: false,
      searchable: false,
      viewColumns: false,
    },
  },
  {
    name: "nome",
    label: "Nome",
    // options: {
    //   filterType: "textField",
    //   customFilterListOptions: { render: (v) => `Empresa: ${v}` },
    // },
  },
  //   {
  //     name: "companyName",
  //     label: "Razão Social",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `Razão Social: ${v}` },
  //     },
  //   },
  //   {
  //     name: "fantasyName",
  //     label: "Nome Fantasia",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `Nome Fantasia: ${v}` },
  //     },
  //   },
  //   {
  //     name: "cnpj",
  //     label: "CNPJ",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `CNPJ: ${v}` },
  //     },
  //   },
  //   {
  //     name: "status",
  //     label: "Status",
  //     options: {
  //       customBodyRender: (value) => getStatusLabel(value, statusList),
  //       filterType: "multiselect",
  //       customFilterListOptions: { render: (v) => `Status: ${v}` },
  //       filterOptions: {
  //         names: _.map(statusList, "value"),
  //       },
  //     },
  //   },
  //   {
  //     name: "cep",
  //     label: "CEP",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `CEP: ${v}` },
  //     },
  //   },
  //   {
  //     name: "address",
  //     label: "Endereço",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `Endereço: ${v}` },
  //     },
  //   },
  //   {
  //     name: "city",
  //     label: "Cidade",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `Cidade: ${v}` },
  //     },
  //   },
  //   {
  //     name: "state",
  //     label: "Estado",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `Estado: ${v}` },
  //     },
  //   },
  //   {
  //     name: "country",
  //     label: "País",
  //     options: {
  //       filterType: "textField",
  //       customFilterListOptions: { render: (v) => `País: ${v}` },
  //     },
  //   },
  {
    name: "Ações",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataindex) => {
        //   const dataRow = companies && companies[dataindex].companyId;
        //   return <ArrowNavigate linkTo={`/app/material/company/${dataRow}`} />;
        return <ArrowNavigate linkTo={`/userList`} />;
      },
    },
  },
];

export default columnsData;
