import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendEmailValidation } from "./service";

export const emailValidation = createAsyncThunk(
  "validation/emailValidation",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await sendEmailValidation(body);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  status: "idle",
  message: "",
};

const validation = createSlice({
  name: "validation",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(emailValidation.pending, (state) => {
        return (state = { ...state, status: "loading" });
      })
      .addCase(emailValidation.fulfilled, (state, action) => {
        return (state = {
          ...state,
          status: "completed",
          message: action.payload?.message,
        });
      })
      .addCase(emailValidation.rejected, (state, action) => {
        return (state = {
          ...state,
          status: "failed",
          message: action.payload?.message,
        });
      });
  },
});

export default validation.reducer;
