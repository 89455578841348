// import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
// import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers";
// import systemUserReducer from "./ducks/systemUser";
// import userReducer from "./ducks/User";
// import signerReducer from "./ducks/Signer";

// const reducers = combineReducers({
//   // systemUser: systemUserReducer,
//   user: userReducer,
//   signer: signerReducer,
// });

const persistConfig = {
  key: "vileveWay-Admin",
  version: 1,
  storage,
  blacklist: ["group", "validation", "user", "status"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
});

export default store;
