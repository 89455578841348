import React from "react";
import ArrowNavigate from "../../../../../components/ArrowNavegate";
import Label from "../../../../../components/Label";

const getColumns = (data) => {
  const columnsData = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        display: false,
        download: false,
        print: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        filterType: "textField",
        customFilterListOptions: { render: (v) => `Nome: ${v}` },
      },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "cpf",
      label: "CPF",
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value) => {
          let color = "";
          if (value === "ATIVO") {
            color = "info";
          } else if (value === "INATIVO") {
            color = "error";
          } else if (value === "BLOQUEADO") {
            color = "warning";
          } else {
            return <Label color="error">SEM STATUS</Label>;
          }
          return <Label color={color}>{value}</Label>;
        },
        // filterType: "multiselect",
        // customFilterListOptions: { render: (v) => `Status: ${v}` },
        // filterOptions: {
        //   names: _.map(statusList, "value"),
        // },
      },
    },
    //   {
    //     name: "companyName",
    //     label: "Razão Social",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `Razão Social: ${v}` },
    //     },
    //   },
    //   {
    //     name: "fantasyName",
    //     label: "Nome Fantasia",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `Nome Fantasia: ${v}` },
    //     },
    //   },
    //   {
    //     name: "cnpj",
    //     label: "CNPJ",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `CNPJ: ${v}` },
    //     },
    //   },
    //   {
    //     name: "status",
    //     label: "Status",
    //     options: {
    //       customBodyRender: (value) => getStatusLabel(value, statusList),
    //       filterType: "multiselect",
    //       customFilterListOptions: { render: (v) => `Status: ${v}` },
    //       filterOptions: {
    //         names: _.map(statusList, "value"),
    //       },
    //     },
    //   },
    //   {
    //     name: "cep",
    //     label: "CEP",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `CEP: ${v}` },
    //     },
    //   },
    //   {
    //     name: "address",
    //     label: "Endereço",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `Endereço: ${v}` },
    //     },
    //   },
    //   {
    //     name: "city",
    //     label: "Cidade",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `Cidade: ${v}` },
    //     },
    //   },
    //   {
    //     name: "state",
    //     label: "Estado",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `Estado: ${v}` },
    //     },
    //   },
    //   {
    //     name: "country",
    //     label: "País",
    //     options: {
    //       filterType: "textField",
    //       customFilterListOptions: { render: (v) => `País: ${v}` },
    //     },
    //   },
    {
      name: "Ações",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          // const dataRow = companies && companies[dataindex].companyId;
          const dataRow = data && data[dataIndex].id;
          //   return <ArrowNavigate linkTo={`/app/material/company/${dataRow}`} />;
          return (
            <ArrowNavigate
              title={"Visualizar usuário"}
              linkTo={`/editUser/${dataRow}`}
            />
          );
        },
      },
    },
  ];

  return columnsData;
};

export default getColumns;
