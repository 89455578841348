import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import {
  Container,
  Tab,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { CustomTab, useStyles } from "./styles";
import TabPanel from "./TabPanel";
import Form from "./Form";
import RelatedGroups from "./RelatedGroups";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedGroups,
  clearUser,
  getOnlyUser,
  editUser,
  deleteUser,
} from "../../../../store/ducks/User";
import MoreActions from "../../../../components/MoreActions";
import { editGroupRelationship } from "../../../../store/ducks/Group";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import UserInfo from "./UserInfo";
import { Save } from "@material-ui/icons";
import { Edit } from "react-feather";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const EditUser = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const state = useSelector((state) => state);
  const { id } = useParams();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [showTab, setShowTab] = useState(false);
  const { status: userStatus, message, type } = state.user;
  const validationSchema = yup.object({
    nome: yup
      .string()
      .required("Nome é obrigatório")
      .min(10, "Nome deve conter no minimo 10 caracteres"),
    email: yup
      .string()
      .email("Insira um email valido")
      .required("Email é obrigatório"),
    status: yup.string().required("Status é obrigatório"),
  });

  useEffect(() => {
    dispatch(getOnlyUser(id));
  }, []);

  useEffect(() => {
    if (userStatus === "loading") {
      setOpen(true);
    } else if (userStatus === "completed") {
      setOpen(false);
      if (!!message) {
        enqueueSnackbar(message, {
          variant: "success",
        });
        if (type === "deleteById") {
          history.push("/userList");
        }
      }
    } else if (userStatus === "failed") {
      setOpen(false);
      if (!!message) {
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
  }, [userStatus]);

  useEffect(() => {
    return () => {
      dispatch(addSelectedGroups([]));
      dispatch(clearUser());
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      nome: state.user.currentUser[0].nome,
      status: state.user.currentUser[0].status,
      email: state.user.currentUser[0].email,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      const body = { ...values, id };
      dispatch(await editUser(body));
      const editRelationship = { id, putBody: [] };
      for (const key in state.user.selectedRelatedGroups) {
        editRelationship.putBody.push({
          fk_id_usu_adm: id,
          fk_id_grupo: state.user.selectedRelatedGroups[key].id,
        });
      }
      setTimeout(() => {
        dispatch(editGroupRelationship(editRelationship));
      }, 1000);
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleEditUser = () => {
    setShowTab(!showTab);
  };

  const actions = [
    {
      icon: <Save />,
      name: "Salvar",
      handleClick: () => {
        formik.handleSubmit();
      },
      hidden: !showTab,
    },
    {
      icon: <Edit />,
      name: "Editar",
      handleClick: () => {
        setShowTab(!showTab);
      },
      hidden: showTab,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        {showTab ? (
          <CardContent>
            <form>
              <CustomTab
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                //   textColor='primary'
                //   variant='fullWidth'
                aria-label="User Tabs"
              >
                <Tab label="Usuário" {...a11yProps(0)} />
                <Tab label="Grupos" {...a11yProps(1)} />
              </CustomTab>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Box mt={3}>
                    <Form formik={formik} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Box mt={3}>
                    <RelatedGroups />
                  </Box>
                </TabPanel>
              </SwipeableViews>
            </form>
          </CardContent>
        ) : (
          <CardContent>
            <CustomTab
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              //   textColor='primary'
              //   variant='fullWidth'
              aria-label="User Tabs"
            >
              <Tab label="Informação do usuário" {...a11yProps(0)} />
            </CustomTab>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <UserInfo editUser={handleEditUser} />
              </TabPanel>
            </SwipeableViews>
          </CardContent>
        )}
      </Card>
      <MoreActions actions={actions} />
    </Container>
  );
};

export default EditUser;
