import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import {
  Container,
  Tab,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { CustomTab, useStyles } from "./styles";
import TabPanel from "./TabPanel";
import Form from "./Form";
import RelatedGroups from "./RelatedGroups";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedGroups,
  createUser,
  clearUser,
  userStatus as getStatus,
} from "../../../../store/ducks/User";
import { createGroupRelationship } from "../../../../store/ducks/Group";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Save } from "@material-ui/icons";
import MoreActions from "../../../../components/MoreActions";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const User = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    user: {
      status: userStatus,
      message,
      newUser: newUserState,
      selectedRelatedGroups,
      type,
    },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [readOnlyButton, setReadOnlyButton] = useState(false);

  const validationSchema = yup.object({
    nome: yup
      .string()
      .required("Nome é obrigatório")
      .min(10, "Nome deve conter no minimo 10 caracteres"),
    email: yup
      .string()
      .email("Insira um email valido")
      .required("Email é obrigatório"),
    status: yup.string().required("Status é obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      nome: "",
      status: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(await createUser(values));
    },
  });

  useEffect(() => {
    dispatch(getStatus());
  }, []);

  useEffect(() => {
    if (newUserState.length !== 0) {
      const body = [];
      for (const key in selectedRelatedGroups) {
        body.push({
          fk_id_usu_adm: newUserState[0].id,
          fk_id_grupo: selectedRelatedGroups[key].id,
        });
      }
      dispatch(createGroupRelationship(body));
    }
  }, [newUserState]);

  useEffect(() => {
    if (userStatus === "loading" && type === "createUser") {
      setOpen(true);
    } else if (userStatus === "failed" && type === "createUser") {
      setOpen(false);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } else if (userStatus === "completed" && type === "createUser") {
      setOpen(false);
      enqueueSnackbar("Usuário Cadastrado com sucesso", {
        variant: "success",
      });
      setReadOnlyButton(true);
    }
  }, [userStatus, message]);

  useEffect(() => {
    return () => {
      dispatch(addSelectedGroups([]));
      dispatch(clearUser());
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const actions = [
    {
      icon: <Save />,
      name: "Salvar",
      hidden: false,
      handleClick: () => {
        formik.handleSubmit();
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <Backdrop className={classes.backdrop} open={open} color="primary">
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className={classes.root}>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <CustomTab
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              //   textColor='primary'
              //   variant='fullWidth'
              aria-label="User Tabs"
            >
              <Tab label="Usuário" {...a11yProps(0)} />
              <Tab label="Grupos" {...a11yProps(1)} />
            </CustomTab>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Form formik={formik} readOnlyButton={readOnlyButton} />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <RelatedGroups />
              </TabPanel>
            </SwipeableViews>
          </form>
        </CardContent>
      </Card>
      <MoreActions actions={actions} />
    </Container>
  );
};

export default User;
