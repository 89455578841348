import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import ImgUpload from "../../../../../components/ImgUpload";
import SkeletonImgDocuments from "../../../../../components/SkeletonImgDocuments";
import { clearImgUpload } from "../../../../../store/ducks/Shopkeeper";
import { useDispatch, useSelector } from "react-redux";

const Documents = ({ formik }) => {
  const dispatch = useDispatch();
  const [img, setImg] = useState([]);
  const {
    shopkeeper: { imgData },
    status: { data: statusData },
  } = useSelector((state) => state);
  useEffect(() => {
    return () => {
      dispatch(clearImgUpload());
    };
  }, []);

  useEffect(() => {
    imgData.length > 0 && setImg(imgData);
  }, [imgData]);

  return (
    <Grid container direction="column" spacing={2}>
      {img && img.length > 0 ? (
        img.map(({ id, base64, categoria, status, descricao }) => {
          status = status ?? "";
          return (
            <>
              <Grid item xs={9} sm={8} md={7} lg={3} xl={3}>
                <TextField
                  id={id}
                  name={`documentos.${categoria}`}
                  label="Status"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={status === "" ? true : false}
                  select
                  value={formik.values.documentos[categoria]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {statusData &&
                    statusData.map(({ status, info }) => {
                      return (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={4} xl={4} key={categoria}>
                <Typography variant="subtitle1">{descricao}</Typography>
                <ImgUpload
                  name={categoria}
                  formik={formik}
                  category={categoria}
                  base64={base64 && `data:image/png;base64,${base64}`}
                  showButton={false}
                  status={status}
                  showDivOpacity={true}
                />
                <br />
              </Grid>
            </>
          );
        })
      ) : (
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <SkeletonImgDocuments
            widthImg={360}
            widthText={360}
            heightImg={250}
          />
          <SkeletonImgDocuments
            widthImg={360}
            widthText={360}
            heightImg={250}
          />
          <SkeletonImgDocuments
            widthImg={360}
            widthText={360}
            heightImg={250}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Documents;
